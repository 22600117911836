<template>
    <div>
        <v-tabs v-model="active" height="35" background-color="primary" slider-color="logo">
            <v-tab ripple>
                Speed profile
            </v-tab>
            <v-tab ripple>
                Compare ships
            </v-tab>
            <v-tab ripple>
                Weather track
            </v-tab>
            <v-tabs-items v-model="active" touchless>
                <v-tab-item eager>
                    <v-card>
                        <v-container fluid grid-list-lg>
                            <v-layout wrap align-center>
                                <v-flex xs12 sm4 lg2 d-flex>
                                    <v-select v-model="analyze.shipId" :items="shipsList" item-key="id" item-text="[name]" item-value="id"
                                        label="Select ship" />
                                </v-flex>
                                <v-flex xs6 sm4 md2 d-flex>
                                    <v-menu ref="sdmenu" v-model="startDateMenu" :close-on-content-click="true" :return-value.sync="analyze.startDate"
                                        transition="scale-transition" offset-y min-width="290px">
                                        <template #activator="{ on }">
                                            <v-text-field v-model="analyze.startDate" label="Start date">
                                                <template #prepend>
                                                    <v-icon v-on="on" color="primary">mdi-calendar</v-icon>
                                                </template>
                                            </v-text-field>
                                        </template>
                                        <v-date-picker @change="$refs.sdmenu.save(analyze.startDate)" :first-day-of-week="firstDayOfWeek" :show-week="true"
                                            v-model="analyze.startDate" no-title scrollable>
                                            <v-spacer></v-spacer>
                                            <v-btn text color="primary" @click="startDateMenu = false">Cancel</v-btn>
                                            <v-btn text color="primary" @click="$refs.sdmenu.save(analyze.startDate)">OK</v-btn>
                                        </v-date-picker>
                                    </v-menu>
                                </v-flex>
                                <v-flex xs6 sm3 md2 lg1 d-flex>
                                    <v-menu ref="stmenu" v-model="startTimeMenu" :close-on-content-click="false" :nudge-right="40"
                                        :return-value.sync="analyze.startTime" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                                        <template #activator="{ on }">
                                            <v-text-field v-model="analyze.startTime" label="Start time">
                                                <template #prepend>
                                                    <v-icon v-on="on" color="primary">access_time</v-icon>
                                                </template>
                                            </v-text-field>
                                        </template>
                                        <v-time-picker v-if="startTimeMenu" v-model="analyze.startTime" format="24hr" 
                                            @click:minute="$refs.stmenu.save(analyze.startTime)"></v-time-picker>
                                    </v-menu>
                                </v-flex>
                                <v-flex xs6 sm4 md2 d-flex>
                                    <v-menu ref="edmenu" v-model="endDateMenu" :close-on-content-click="true" :return-value.sync="analyze.endDate"
                                        transition="scale-transition" offset-y min-width="290px">
                                        <template #activator="{ on }">
                                            <v-text-field v-model="analyze.endDate" label="End date">
                                                <template #prepend>
                                                    <v-icon v-on="on" color="primary">mdi-calendar</v-icon>
                                                </template>
                                            </v-text-field>
                                        </template>
                                        <v-date-picker @change="$refs.edmenu.save(analyze.endDate)" :first-day-of-week="firstDayOfWeek" :show-week="true"
                                            v-model="analyze.endDate" no-title scrollable>
                                            <v-spacer></v-spacer>
                                            <v-btn text color="primary" @click="endDateMenu = false">Cancel</v-btn>
                                            <v-btn text color="primary" @click="$refs.edmenu.save(analyze.endDate)">OK</v-btn>
                                        </v-date-picker>
                                    </v-menu>
                                </v-flex>
                                <v-flex xs6 sm3 md2 lg1 d-flex>
                                    <v-menu ref="etmenu" v-model="endTimeMenu" :close-on-content-click="false" :nudge-right="40"
                                        :return-value.sync="analyze.endTime" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                                        <template #activator="{ on }">
                                            <v-text-field v-model="analyze.endTime" label="End time">
                                                <template #prepend>
                                                    <v-icon v-on="on" color="primary">access_time</v-icon>
                                                </template>
                                            </v-text-field>
                                        </template>
                                        <v-time-picker v-if="endTimeMenu" v-model="analyze.endTime" format="24hr" 
                                            @click:minute="$refs.etmenu.save(analyze.endTime)"></v-time-picker>
                                    </v-menu>
                                </v-flex>
                                <v-spacer />
                                <v-btn color="error" @click="clearAnalyzeChart()">
                                    Clear <v-icon right class="hidden-xs-only">cancel</v-icon>
                                </v-btn>
                                <v-btn class="ml-4" color="primary" @click="callFetchAnalyticsData(analyze)"
                                    :disabled="!analyze.shipId && !analyze.startDate && !analyze.startTime && !analyze.endDate && !analyze.endTime">
                                    Get data
                                    <v-icon right class="hidden-xs-only">check</v-icon>
                                </v-btn>
                            </v-layout>
                        </v-container>
                        <v-container fluid class="pa-0" v-show="chart && chart.data && chart.data.length > 0">
                            <v-layout wrap>
                                <v-flex xs12 sm12 lg8>
                                    <v-card class="eventcard primarytext--text">
                                        <v-container fluid grid-list-md class="pa-0">
                                            <div class="chart" ref="chartdiv">
                                            </div>
                                            <video class="video" width="600px" controls></video>
                                        </v-container>
                                    </v-card>
                                </v-flex>
                                <v-flex xs12 sm12 lg4>
                                    <v-map v-if="chart && chart.data && chart.data.length > 0" class="map" ref="map" :zoom="zoom" :center="center"
                                        :bounds="bounds" :options="mapOptions">
                                        <v-tilelayer :url="url" :options="{tileSize: 512, crossOrigin: true, zoomOffset: -1}"></v-tilelayer>
                                        <v-tilelayer url="https://tiles.openseamap.org/seamark/{z}/{x}/{y}.png"></v-tilelayer>
                                        <v-geojson v-if="routeGeojson" :geojson="routeGeojson" :options="routeOptions[0]"></v-geojson>
                                        <v-geojson v-if="marker && marker.shipGeojson" :geojson="marker.shipGeojson" :options="shipGeojsonOptions"></v-geojson>
                                        <v-rotated-marker v-if="marker && marker.predictor && marker.predictor.latLng" :lat-lng="marker.predictor.latLng"
                                            :icon="marker.predictor.icon" :rotationAngle="marker.predictor.direction"
                                            :rotationOrigin="marker.predictor.rotationOrigin">
                                        </v-rotated-marker>
                                        <v-rotated-marker v-if="marker && marker.latitude && marker.longitude && marker.latLng" :lat-lng="marker.latLng"
                                            :icon="marker.icon" :rotationAngle="marker.rotation">
                                            <l-tooltip v-if="marker.tooltip" :content="marker.tooltip" :options="tooltipOptions" />
                                            <l-popup v-if="marker.popup" :content="marker.popup"></l-popup>
                                        </v-rotated-marker>
                                        <span v-if="marker && marker.circles">
                                            <l-circle v-for="(circle, index) in marker.circles" :key="random()+index" :lat-lng="circle.center"
                                                :radius="circle.radius" :fillOpacity="0.1" :color="circle.color" :weight="circle.weight"></l-circle>
                                        </span>
                                        <div v-if="marker && marker.sog" class="aisinfo primarytext--text">
                                            {{ Math.round(marker.sog*10)/10 }}kn <span :class="getNavstat(marker.navstat).color + '--text'">
                                                {{
                                                getNavstat(marker.navstat).status }}</span><br />
                                            {{ decimalToDmm(marker.latitude, false) }} {{
                                            decimalToDmm(marker.longitude, true) }}
                                        </div>
                                        <div v-if="marker && marker.roll_max" class="sensorinfo primarytext--text">
                                            Roll max: {{
                                            Math.round(marker.roll_max*1000)/1000 }}° / Pitch max: {{
                                            Math.round(marker.pitch_max*1000)/1000 }}°
                                        </div>
                                        <div class="map-controls">
                                            <v-icon v-show="chartTrackType === 'cursor'" @click="chartTrackType = 'lineSeries'"
                                                title="Chart cursor movement based ship tracking" class="control-btn" style="top:0px">
                                                border_inner
                                            </v-icon>
                                            <v-icon v-show="chartTrackType === 'lineSeries'" @click="chartTrackType = null"
                                                title="Line series hover based ship tracking" class="control-btn" style="top:0px">
                                                show_chart
                                            </v-icon>
                                            <v-icon v-show="chartTrackType === null" @click="chartTrackType = 'cursor'" title="No ship tracking on map"
                                                class="control-btn" style="top:0px">
                                                block
                                            </v-icon>
                                            <v-icon @click="chartPan = false" title="Zoom and pan map to ship position" class="control-btn" style="top:40px">
                                                open_with
                                            </v-icon>
                                            <v-icon v-show="!chartPan" @click="chartPan = true" title="No zoom or pan to ship position" class="control-btn"
                                                style="font-size:30px;top:40px;background:rgba(255, 255, 255, 0.5)">
                                                clear
                                            </v-icon>
                                        </div>
                                    </v-map>
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </v-card>
                </v-tab-item>
                <v-tab-item eager>
                    <v-card style="min-height:400px !important">
                        <v-container fluid grid-list-lg>
                            <v-layout wrap align-center>
                                <v-flex xs12 sm6 d-flex>
                                    <v-select v-model="compareId1" @change="updateCompareEnabled()" :items="compareList" item-key="shipId" item-text="[name]"
                                        item-value="shipId" attach chips label="Select ship 1"></v-select>
                                    <v-select v-model="compareId2" @change="updateCompareEnabled()" :items="compareList" item-key="shipId" item-text="[name]"
                                        item-value="shipId" attach chips label="Select ship 2"></v-select>
                                </v-flex>
                                <v-spacer />
                                <v-btn color="error" @click="clearCompareChart()">
                                    Clear <v-icon right class="hidden-xs-only">cancel</v-icon>
                                </v-btn>
                                <v-btn color="primary" class="ml-4" @click="callFetchCompareData(compare)" :disabled="!compare">
                                    Get data
                                    <v-icon right class="hidden-xs-only">check</v-icon>
                                </v-btn>
                                <div>
                                    <v-layout wrap align-center v-for="(item, index) in compare" :key="item.tempId">
                                        <v-flex xs12 sm2 d-flex>
                                            <b>{{ item.name }}</b>
                                        </v-flex>

                                        <v-flex xs6 sm4 md2 d-flex>
                                            <v-menu ref="csdmenu" v-model="item.startDateMenu" :close-on-content-click="true"
                                                :return-value.sync="item.startDate" transition="scale-transition" offset-y min-width="290px">
                                                <template #activator="{ on }">
                                                    <v-text-field v-model="item.startDate" label="Start date" @change="setEndDateTime(item)">
                                                        <template #prepend>
                                                            <v-icon v-on="on" color="primary">mdi-calendar</v-icon>
                                                        </template>
                                                    </v-text-field>
                                                </template>
                                                <v-date-picker @change="$refs.csdmenu[index].save(item.startDate)" :first-day-of-week="firstDayOfWeek"
                                                    :show-week="true" v-model="item.startDate" no-title scrollable>
                                                    <v-spacer></v-spacer>
                                                    <v-btn text color="primary" @click="item.startDateMenu = false">Cancel</v-btn>
                                                    <v-btn text color="primary" @click="$refs.csdmenu[index].save(item.startDate)">OK</v-btn>
                                                </v-date-picker>
                                            </v-menu>
                                        </v-flex>
                                        <v-flex xs6 sm3 md2 d-flex>
                                            <v-menu ref="cstmenu" v-model="item.startTimeMenu" :close-on-content-click="false" :nudge-right="40"
                                                :return-value.sync="item.startTime" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                                                <template #activator="{ on }">
                                                    <v-text-field v-model="item.startTime" label="Start time" @change="setEndDateTime(item)">
                                                        <template #prepend>
                                                            <v-icon v-on="on" color="primary">access_time</v-icon>
                                                        </template>
                                                    </v-text-field>
                                                </template>
                                                <v-time-picker v-if="item.startTimeMenu" v-model="item.startTime" format="24hr" 
                                                    @click:minute="$refs.cstmenu[index].save(item.startTime)"></v-time-picker>
                                            </v-menu>
                                        </v-flex>
                                        <v-flex xs6 sm4 md2 d-flex>
                                            <v-menu ref="cedmenu" v-model="item.endDateMenu" :close-on-content-click="true" :return-value.sync="item.endDate"
                                                transition="scale-transition" offset-y min-width="290px">
                                                <template #activator="{ on }">
                                                    <v-text-field v-model="item.endDate" label="End date">
                                                        <template #prepend>
                                                            <v-icon v-on="on" color="primary">mdi-calendar</v-icon>
                                                        </template>
                                                    </v-text-field>
                                                </template>
                                                <v-date-picker @change="$refs.cedmenu[index].save(item.endDate)" :first-day-of-week="firstDayOfWeek"
                                                    :show-week="true" v-model="item.endDate" no-title scrollable>
                                                    <v-spacer></v-spacer>
                                                    <v-btn text color="primary" @click="item.endDateMenu = false">Cancel</v-btn>
                                                    <v-btn text color="primary" @click="$refs.cedmenu[index].save(item.endDate)">OK</v-btn>
                                                </v-date-picker>
                                            </v-menu>
                                        </v-flex>
                                        <v-flex xs6 sm3 md2 d-flex>
                                            <v-menu ref="cetmenu" v-model="item.endTimeMenu" :close-on-content-click="false" :nudge-right="40"
                                                :return-value.sync="item.endTime" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                                                <template #activator="{ on }">
                                                    <v-text-field v-model="item.endTime" label="End time">
                                                        <template #prepend>
                                                            <v-icon v-on="on" color="primary">access_time</v-icon>
                                                        </template>
                                                    </v-text-field>
                                                </template>
                                                <v-time-picker v-if="item.endTimeMenu" v-model="item.endTime" format="24hr" 
                                                    @click:minute="$refs.cetmenu[index].save(item.endTime)">
                                                </v-time-picker>
                                            </v-menu>
                                        </v-flex>
                                    </v-layout>
                                </div>
                            </v-layout>
                        </v-container>
                        <v-container fluid class="pa-0" v-show="chart2 && chart2.data && chart2.data.length > 0">
                            <v-layout wrap>
                                <v-flex xs12 lg8>
                                    <v-card class="eventcard primarytext--text">
                                        <v-container fluid grid-list-md class="pa-0">
                                            <div class="chart" ref="chartdiv2">
                                            </div>
                                        </v-container>
                                    </v-card>
                                </v-flex>
                                <v-flex xs12 sm12 lg4>
                                    <v-map v-if="chart2 && chart2.data && chart2.data.length > 0" class="map" ref="map2" :zoom="zoom" :center="center"
                                        :bounds="bounds" :options="mapOptions">
                                        <v-tilelayer :url="url" :options="{tileSize: 512, crossOrigin: true, zoomOffset: -1}"></v-tilelayer>
                                        <v-tilelayer url="https://tiles.openseamap.org/seamark/{z}/{x}/{y}.png"></v-tilelayer>
                                        <span v-if="routeGeojsons && routeGeojsons.length > 0">
                                            <v-geojson v-for="(geojson, index) in routeGeojsons" :key="index" :geojson="geojson" :options="routeOptions[index]">
                                            </v-geojson>
                                        </span>
                                        <span v-for="(m, i) in markers" :key="i">
                                            <span v-if="m && m.shipId">
                                                <v-geojson v-if="m && m.shipGeojson" :geojson="m.shipGeojson" :options="shipGeojsonOptions"></v-geojson>
                                                <v-rotated-marker v-if="m && m.predictor && m.predictor.latLng" :lat-lng="m.predictor.latLng"
                                                    :icon="m.predictor.icon" :rotationAngle="m.predictor.direction"
                                                    :rotationOrigin="m.predictor.rotationOrigin">
                                                </v-rotated-marker>
                                                <v-rotated-marker v-if="m && m.latLng" :lat-lng="m.latLng" :icon="m.icon" :rotationAngle="m.rotation">
                                                    <l-tooltip v-if="m.tooltip" :content="m.tooltip" :options="tooltipOptions" />
                                                    <l-popup v-if="m.popup" :content="m.popup"></l-popup>
                                                </v-rotated-marker>
                                                <span v-if="m.circles">
                                                    <l-circle v-for="(circle, index2) in m.circles" :key="random()+index2" :lat-lng="circle.center"
                                                        :radius="circle.radius" :fillOpacity="0.1" :color="circle.color" :weight="circle.weight"></l-circle>
                                                </span>
                                            </span>
                                        </span>
                                        <div class="map-controls">
                                            <v-icon v-show="chartTrackType2 === 'cursor'" @click="chartTrackType2 = 'lineSeries'"
                                                title="Chart cursor movement based ship tracking" class="control-btn" style="top:0">
                                                border_inner
                                            </v-icon>
                                            <v-icon v-show="chartTrackType2 === 'lineSeries'" @click="chartTrackType2 = null"
                                                title="Line series hover based ship tracking" class="control-btn" style="top:0">
                                                show_chart
                                            </v-icon>
                                            <v-icon v-show="chartTrackType2 === null" @click="chartTrackType2 = 'cursor'" title="No ship tracking on map"
                                                class="control-btn" style="top:0">
                                                block
                                            </v-icon>
                                            <v-icon v-show="chart2Pan === 'bounds'" @click="chart2Pan = 'ship1'" title="Zoom/pan to keep both ships visible"
                                                class="control-btn" style="top:40px">
                                                open_with
                                            </v-icon>
                                            <div v-show="chart2Pan === 'ship1'" @click="chart2Pan = 'ship2'" title="Zoom/pan map to ship 1 position"
                                                class="control-btn" style="top:40px;font-size:18px;color:#777">
                                                1
                                            </div>
                                            <div v-show="chart2Pan === 'ship2'" @click="chart2Pan = null" title="Zoom/pan map to ship 2 position"
                                                class="control-btn" style="top:40px;font-size:18px;color:#777">
                                                2
                                            </div>
                                            <v-icon v-show="chart2Pan === null" @click="chart2Pan = 'bounds'" title="No zoom or pan to ship position"
                                                class="control-btn" style="top:40px">
                                                block
                                            </v-icon>
                                        </div>
                                    </v-map>
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </v-card>
                </v-tab-item>
                <v-tab-item eager>
                    <v-card>
                        <v-container fluid grid-list-lg>
                            <v-layout wrap align-center>
                                <v-flex xs12 sm4 lg2 d-flex>
                                    <v-select v-model="weather.shipId" :items="shipsList" item-key="id" item-text="[name]" item-value="id"
                                        label="Select ship" />
                                </v-flex>

                                <v-flex xs6 sm4 md2 d-flex>
                                    <v-menu ref="wsdmenu" v-model="wStartDateMenu" :close-on-content-click="true" :return-value.sync="weather.startDate"
                                        transition="scale-transition" offset-y min-width="290px">
                                        <template #activator="{ on }">
                                            <v-text-field v-model="weather.startDate" label="Start date">
                                                <template #prepend>
                                                    <v-icon v-on="on" color="primary">mdi-calendar</v-icon>
                                                </template>
                                            </v-text-field>
                                        </template>
                                        <v-date-picker @change="$refs.wsdmenu.save(weather.startDate)" :first-day-of-week="firstDayOfWeek" :show-week="true"
                                            v-model="weather.startDate" no-title scrollable>
                                            <v-spacer></v-spacer>
                                            <v-btn text color="primary" @click="wStartDateMenu = false">Cancel</v-btn>
                                            <v-btn text color="primary" @click="$refs.wsdmenu.save(weather.startDate)">OK</v-btn>
                                        </v-date-picker>
                                    </v-menu>
                                </v-flex>
                                <v-flex xs6 sm3 md2 lg1 d-flex>
                                    <v-menu ref="wstmenu" v-model="wStartTimeMenu" :close-on-content-click="false" :nudge-right="40"
                                        :return-value.sync="weather.startTime" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                                        <template #activator="{ on }">
                                            <v-text-field v-model="weather.startTime" label="Start time">
                                                <template #prepend>
                                                    <v-icon v-on="on" color="primary">access_time</v-icon>
                                                </template>
                                            </v-text-field>
                                        </template>
                                        <v-time-picker v-if="wStartTimeMenu" v-model="weather.startTime" format="24hr" 
                                            @click:minute="$refs.wstmenu.save(weather.startTime)"></v-time-picker>
                                    </v-menu>
                                </v-flex>
                                <v-flex xs6 sm4 md2 d-flex>
                                    <v-menu ref="wedmenu" v-model="wEndDateMenu" :close-on-content-click="true" :return-value.sync="weather.endDate"
                                        transition="scale-transition" offset-y min-width="290px">
                                        <template #activator="{ on }">
                                            <v-text-field v-model="weather.endDate" label="End date">
                                                <template #prepend>
                                                    <v-icon v-on="on" color="primary">mdi-calendar</v-icon>
                                                </template>
                                            </v-text-field>
                                        </template>
                                        <v-date-picker @change="$refs.wedmenu.save(weather.endDate)" :first-day-of-week="firstDayOfWeek" :show-week="true"
                                            v-model="weather.endDate" no-title scrollable>
                                            <v-spacer></v-spacer>
                                            <v-btn text color="primary" @click="wEndDateMenu = false">Cancel</v-btn>
                                            <v-btn text color="primary" @click="$refs.wedmenu.save(weather.endDate)">OK</v-btn>
                                        </v-date-picker>
                                    </v-menu>
                                </v-flex>
                                <v-flex xs6 sm3 md2 lg1 d-flex>
                                    <v-menu ref="wetmenu" v-model="wEndTimeMenu" :close-on-content-click="false" :nudge-right="40"
                                        :return-value.sync="weather.endTime" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                                        <template #activator="{ on }">
                                            <v-text-field v-model="weather.endTime" label="End time">
                                                <template #prepend>
                                                    <v-icon v-on="on" color="primary">access_time</v-icon>
                                                </template>
                                            </v-text-field>
                                        </template>
                                        <v-time-picker v-if="wEndTimeMenu" v-model="weather.endTime" format="24hr" 
                                            @click:minute="$refs.wetmenu.save(weather.endTime)"></v-time-picker>
                                    </v-menu>
                                </v-flex>

                                <v-flex xs6 sm3 md2 xl1 d-flex>
                                    <v-select @change="setWindLimit()" v-model="windLimit" :items="windLimits" item-key="value" item-text="[name]"
                                        item-value="value" label="Wind treshold" />
                                </v-flex>
                                <v-spacer />
                                <v-btn color="error" @click="clearWeatherChart()">
                                    Clear <v-icon right class="hidden-xs-only">cancel</v-icon>
                                </v-btn>
                                <v-btn color="primary" class="ml-4" @click="callFetchWeatherData(weather)"
                                    :disabled="!weather.shipId && !weather.startDate && !weather.startTime && !weather.endDate && !weather.endTime">
                                    Get data
                                    <v-icon right class="hidden-xs-only">check</v-icon>
                                </v-btn>
                            </v-layout>
                        </v-container>
                        <v-container fluid class="pa-0" v-show="chart3 && chart3.data && chart3.data.length > 0">
                            <v-layout wrap>
                                <v-flex xs12 sm12 lg8>
                                    <v-card class="eventcard primarytext--text">
                                        <v-container fluid grid-list-md class="pa-0">
                                            <div class="chart" ref="chartdiv3">
                                            </div>
                                        </v-container>
                                    </v-card>
                                </v-flex>
                                <v-flex xs12 sm12 lg4>
                                    <v-map v-if="chart3 && chart3.data && chart3.data.length > 0" class="map" ref="map3" :zoom="zoom" :center="center"
                                        :bounds="bounds" :options="mapOptions">
                                        <v-tilelayer :url="url" :options="{tileSize: 512, crossOrigin: true, zoomOffset: -1}"></v-tilelayer>
                                        <v-tilelayer url="https://tiles.openseamap.org/seamark/{z}/{x}/{y}.png"></v-tilelayer>
                                        <v-geojson v-if="routeGeojson3" :geojson="routeGeojson3" :options="routeOptions[0]"></v-geojson>
                                        <v-geojson v-if="marker3 && marker3.shipGeojson" :geojson="marker3.shipGeojson" :options="shipGeojsonOptions">
                                        </v-geojson>
                                        <v-rotated-marker v-if="marker3 && marker3.predictor && marker3.predictor.latLng" :lat-lng="marker3.predictor.latLng"
                                            :icon="marker3.predictor.icon" :rotationAngle="marker3.predictor.direction"
                                            :rotationOrigin="marker3.predictor.rotationOrigin">
                                        </v-rotated-marker>
                                        <v-rotated-marker v-if="marker3 && marker3.latitude && marker3.longitude && marker3.latLng" :lat-lng="marker3.latLng"
                                            :icon="marker3.icon" :rotationAngle="marker3.rotation">
                                            <l-tooltip v-if="marker3.tooltip" :content="marker3.tooltip" :options="tooltipOptions" />
                                            <l-popup v-if="marker3.popup" :content="marker3.popup"></l-popup>
                                        </v-rotated-marker>
                                        <span v-if="marker3 && marker3.circles">
                                            <l-circle v-for="(circle, index) in marker3.circles" :key="random()+index" :lat-lng="circle.center"
                                                :radius="circle.radius" :fillOpacity="0.1" :color="circle.color" :weight="circle.weight"></l-circle>
                                        </span>
                                        <div v-if="marker3 && marker3.sog" class="aisinfo primarytext--text">
                                            {{ Math.round(marker3.sog*10)/10 }}kn <span :class="getNavstat(marker3.navstat).color + '--text'">
                                                {{
                                                getNavstat(marker3.navstat).status }}</span><br />
                                            {{ decimalToDmm(marker3.latitude, false) }} {{ decimalToDmm(marker3.longitude, true) }}
                                        </div>
                                        <div v-if="marker3 && marker3.roll_max" class="sensorinfo primarytext--text">
                                            Roll max: {{
                                            Math.round(marker3.roll_max*1000)/1000 }}° / Pitch max: {{
                                            Math.round(marker3.pitch_max*1000)/1000 }}°
                                        </div>
                                        <div class="map-controls">
                                            <v-icon v-show="chartTrackType === 'cursor'" @click="chartTrackType = 'lineSeries'"
                                                title="Chart cursor movement based ship tracking" class="control-btn" style="top:0">
                                                border_inner
                                            </v-icon>
                                            <v-icon v-show="chartTrackType === 'lineSeries'" @click="chartTrackType = null"
                                                title="Line series hover based ship tracking" class="control-btn" style="top:0">
                                                show_chart
                                            </v-icon>
                                            <v-icon v-show="chartTrackType === null" @click="chartTrackType = 'cursor'" title="No ship tracking on map"
                                                class="control-btn" style="top:0">
                                                block
                                            </v-icon>
                                            <v-icon @click="chartPan = false" title="Zoom and pan map to ship position" class="control-btn" style="top:40px">
                                                open_with
                                            </v-icon>
                                            <v-icon v-show="!chartPan" @click="chartPan = true" title="No zoom or pan to ship position" class="control-btn"
                                                style="font-size:30px;top:40px;background:rgba(255, 255, 255, 0.5)">
                                                clear
                                            </v-icon>
                                        </div>
                                    </v-map>
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </v-card>
                </v-tab-item>
            </v-tabs-items>
        </v-tabs>

        <!-- Components -->
        <StatusDialog />
        <ProgressCircular />
    </div>
</template>

<script>
    /* eslint-disable */
    import L from 'leaflet';
    import {
        LMap,
        LTileLayer,
        LGeoJson,
        LTooltip,
        LPopup,
        LCircle
    } from 'vue2-leaflet';
    import Vue2LeafletRotatedMarker from 'vue2-leaflet-rotatedmarker';
    import {
        mapGetters,
        mapActions,
    } from 'vuex';
    import moment from 'moment';
    import {
        lineString
    } from '@turf/helpers'
    import * as am4core from "@amcharts/amcharts4/core";
    import * as am4charts from "@amcharts/amcharts4/charts";
    import am4themes_animated from "@amcharts/amcharts4/themes/animated";
    import am4themes_dark from "@amcharts/amcharts4/themes/dark";
    import StatusDialog from '../components/StatusDialog.vue';
    import ProgressCircular from '../components/ProgressCircular.vue';
    import {
        shipHelpers,
        tools,
        config
    } from '../helpers';

    am4core.useTheme(am4themes_animated);

    delete L.Icon.Default.prototype._getIconUrl;

    L.Icon.Default.mergeOptions({
        iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
        iconUrl: require('leaflet/dist/images/marker-icon.png'),
        shadowUrl: require('leaflet/dist/images/marker-shadow.png')
    });

    export default {
        name: 'Analytics',
        components: {
            'v-map': LMap,
            'v-tilelayer': LTileLayer,
            'v-geojson': LGeoJson,
            LTooltip,
            LPopup,
            LCircle,
            'v-rotated-marker': Vue2LeafletRotatedMarker,
            StatusDialog,
            ProgressCircular
        },
        data() {
            return {
                // Leaflet specific properties
                zoom: 4,
                center: L.latLng(50, -10),
                mapOptions: {
                    attributionControl: false
                },
                tooltipOptions: {
                    permanent: true,
                    direction: 'top',
                    offset: L.point(0, -10),
                    opacity: 0.8,
                },
                bounds: null,
                shipGeojsonOptions: {
                    style: {
                        "color": "#000000",
                        "weight": 1,
                        "opacity": 0.6
                    }
                },
                routeOptions: [{
                        style: {
                            "color": "#e59165",
                            "weight": 1.5,
                        }
                    },
                    {
                        style: {
                            "color": "#0652DD",
                            "weight": 1.5,
                        }
                    },
                    {
                        style: {
                            "color": "#16a085",
                            "weight": 1.5,
                        }
                    },
                ],
                analyze: {
                    shipId: null,
                    startDate: moment.utc().subtract(1, 'days').format('YYYY-MM-DD'),
                    startTime: '00:00',
                    endDate: moment.utc().format('YYYY-MM-DD'),
                    endTime: moment.utc().format('HH:mm'),
                    type: 'analyze',
                },
                weather: {
                    shipId: null,
                    startDate: moment.utc().subtract(1, 'days').format('YYYY-MM-DD'),
                    startTime: '00:00',
                    endDate: moment.utc().format('YYYY-MM-DD'),
                    endTime: moment.utc().format('HH:mm'),
                    type: 'weather',
                },
                startDateMenu: false,
                startTimeMenu: false,
                endDateMenu: false,
                endTimeMenu: false,
                wStartDateMenu: false,
                wStartTimeMenu: false,
                wEndDateMenu: false,
                wEndTimeMenu: false,
                marker: {
                    latLng: L.latLng(50, -10),
                },
                marker3: {
                    latLng: L.latLng(50, -10),
                },
                markers: [],
                routeGeojson: null,
                routeGeojson3: null,
                routeGeojsons: [],
                ship: null,
                tooltip: null,
                tooltip3: null,
                chartTrackType: 'cursor',
                chartTrackType2: 'cursor',
                timeoutFired: true,
                compareId1: null,
                compareId2: null,
                compareList: [],
                compare: [],
                chart: {},
                chart2: {},
                chart3: {},
                active: null,
                trendSeries: {},
                limitSeries: {},
                chartPan: true,
                chart2Pan: 'bounds',
                windLimit: 7.9,
                windLimits: [{
                        value: 0.5,
                        name: 'Over 0BF'
                    },
                    {
                        value: 1.5,
                        name: 'Over 1BF'
                    },
                    {
                        value: 3.3,
                        name: 'Over 2BF'
                    },
                    {
                        value: 5.5,
                        name: 'Over 3BF'
                    },
                    {
                        value: 7.9,
                        name: 'Over 4BF'
                    },
                    {
                        value: 10.7,
                        name: 'Over 5BF'
                    },
                    {
                        value: 13.8,
                        name: 'Over 6BF'
                    },
                    {
                        value: 17.1,
                        name: 'Over 7BF'
                    },
                    {
                        value: 20.7,
                        name: 'Over 8BF'
                    },
                    {
                        value: 24.4,
                        name: 'Over 9BF'
                    },
                    {
                        value: 28.4,
                        name: 'Over 10BF'
                    },
                    {
                        value: 32.6,
                        name: 'Over 11BF'
                    },
                ]
            }
        },
        mounted() {
            // Create charts
            if (this.getDarkMode()) {
                am4core.useTheme(am4themes_dark);
            }
            this.createCharts();

            // Preselect first ship and create compare array
            this.fetchShips().then((data) => {
                this.analyze.shipId = data[0].id;
                this.weather.shipId = data[0].id;
                for (const ship of data) {
                    this.compareList.push({
                        name: ship.name,
                        shipId: ship.id,
                        startDate: moment.utc().subtract(1, 'days').format('YYYY-MM-DD'),
                        startTime: '00:00',
                        endDate: moment.utc().format('YYYY-MM-DD'),
                        endTime: '00:00',
                    })
                }
            });
        },
        beforeDestroy() {
            this.disposeCharts();
        },
        watch: {
            getDarkModeComputed(newValue) {
                this.disposeCharts();
                if (newValue) {
                    am4core.useTheme(am4themes_dark);
                } else {
                    am4core.unuseTheme(am4themes_dark);
                }
                // Create charts
                this.createCharts();
            }
        },
        computed: {
            ...mapGetters({
                getDarkModeComputed: 'users/getDarkMode',
            }),
            url() {
                if (this.getDarkMode()) {
                    return 'https://api.maptiler.com/maps/d0b6607a-df0c-4660-af62-030764e2efc4/{z}/{x}/{y}.png?key=' + config.apiKey;
                } else {
                    return 'https://api.maptiler.com/maps/511be076-e981-4e63-8d86-7f23e4d3e7f5/{z}/{x}/{y}.png?key=' + config.apiKey;
                }
            },
            ships() {
                return this.getShips();
            },
            shipsList() {
                let ships = JSON.parse(JSON.stringify(this.getShips()))
                return ships.map((o) => {
                    o.name = o.external === 1 ? o.name + ' (EXT)' : o.name;
                    return o;
                });
            },
            firstDayOfWeek() {
                return this.getFirstDayOfWeek();
            },
        },
        methods: {
            ...mapGetters({
                getDarkMode: 'users/getDarkMode',
                getDynamic: 'data/getDynamic',
                getShips: 'data/getShips',
                getAnalyticsData: 'data/getAnalyticsData',
                getFirstDayOfWeek: 'data/getFirstDayOfWeek',
            }),
            ...mapActions({
                fetchShips: 'data/fetchShips',
                fetchAnalyticsData: 'data/fetchAnalyticsData'
            }),
            disposeCharts() {
                if (this.chart) {
                    this.chart.dispose();
                }
                if (this.chart2) {
                    this.chart2.dispose();
                }
                if (this.chart3) {
                    this.chart3.dispose();
                }
            },
            callFetchAnalyticsData(analyze) {
                if (!this.checkTimespan(analyze, 90)) {
                    return;
                }
                this.ship = this.getShips().filter((o) => {
                    return o.id === analyze.shipId;
                })[0];
                this.tooltip = this.ship && this.ship.name ? this.ship.name : '';
                this.fetchAnalyticsData(analyze).then((data) => {
                    let coords = [];
                    for (const key in data) {
                        if (data[key].time) {
                            data[key].time = moment.utc(data[key].time).toDate();
                        }
                        if (data[key].eventTime) {
                            data[key].eventTime = moment.utc(data[key].eventTime).toDate();
                        }
                        if (data[key].longitude && data[key].latitude) {
                            coords.push([data[key].longitude, data[key].latitude]);
                        }
                        if (data[key].windSpeedR) {
                            data[key].windKnotsR = Math.round(data[key].windSpeedR * 1.94384 * 10) / 10;
                        }
                    }

                    if (coords.length > 1) {
                        this.routeGeojson = lineString(coords, {
                            name: 'route'
                        });
                    }
                    if (this.chart) {
                        // add dummy event to fix empty events
                        data.push({
                            id: null,
                            eventTypeId: null,
                            description: null,
                            eventTime: data[0].time,
                            shortName: null,
                            name: null,
                        });
                        this.chart.data = data;
                        if (this.chartPan) {
                            this.bounds = L.latLngBounds(coords);
                        }
                        for (const d of data) {
                            if (d && d.latitude && d.longitude) {
                                this.marker = this.trackShipOnMap(d, false, null);
                                break;
                            }
                        }
                    }
                })
            },
            callFetchWeatherData(weather) {
                if (!this.checkTimespan(weather, 30)) {
                    return;
                }
                this.ship = this.getShips().filter((o) => {
                    return o.id === weather.shipId;
                })[0];
                this.tooltip3 = this.ship && this.ship.name ? this.ship.name : '';
                this.fetchAnalyticsData(weather).then((data) => {
                    let coords = [];
                    for (const key in data) {
                        if (data[key].time) {
                            data[key].time = moment.utc(data[key].time).toDate();
                        }
                        if (data[key].longitude && data[key].latitude) {
                            coords.push([data[key].longitude, data[key].latitude]);
                        }
                        if (data[key].tws) {
                            data[key].windKnots = Math.round(data[key].tws * 1.94384 * 10) / 10;
                        }
                        if (data[key].windSpeedR) {
                            data[key].windKnotsR = Math.round(data[key].windSpeedR * 1.94384 * 10) / 10;
                        }
                    }

                    if (coords.length > 1) {
                        this.routeGeojson3 = lineString(coords, {
                            name: 'route'
                        });
                    }
                    if (this.chart3) {
                        this.chart3.data = data;
                        if (this.chartPan) {
                            this.bounds = L.latLngBounds(coords);
                        }
                        for (const d of data) {
                            if (d && d.latitude && d.longitude) {
                                this.marker3 = this.trackShipOnMap(d, false, null);
                                break;
                            }
                        }
                        this.setWindLimit();
                        setTimeout(() => {
                            if (this.chart3.series.getIndex(3).bullets.getIndex(0)) {
                                this.chart3.series.getIndex(3).bullets.getIndex(0).children.getIndex(0).show();
                            }
                        }, 5000);
                    }
                })
            },
            callFetchCompareData(compare) {
                for (const ship of compare) {
                    if (!this.checkTimespan(ship, 90)) {
                        return;
                    }
                }
                this.markers = [];
                if (!this.compareId1) {
                    this.$store.dispatch('alert/error', 'Select ship 1 to compare', {
                        root: true
                    });
                    return;
                } else if (!this.compareId2) {
                    this.$store.dispatch('alert/error', 'Select ship 2 to compare', {
                        root: true
                    });
                    return;
                }
                this.fetchAnalyticsData(compare).then((data) => {
                    if (this.chart2) {
                        let ship1 = [];
                        let ship2 = [];
                        // let ship3 = [];
                        this.routeGeojsons = [];
                        for (const key in data) {
                            if (data[key].time1) {
                                data[key].time1 = moment.utc(data[key].time1).toDate();
                                ship1.push([data[key].longitude, data[key].latitude]);
                            } else if (data[key].time2) {
                                data[key].time2 = moment.utc(data[key].time2).toDate();
                                ship2.push([data[key].longitude, data[key].latitude]);
                            }
                        }
                        if (ship1.length > 1) {
                            this.routeGeojsons.push(lineString(ship1, {
                                name: 'route'
                            }));
                        }
                        if (ship2.length > 1) {
                            this.routeGeojsons.push(lineString(ship2, {
                                name: 'route'
                            }));
                        }
                        this.chart2.data = data;
                    }
                })
            },
            trackShipOnMap(marker, compare, shipNro) {
                if (marker && marker.latitude && marker.longitude) {
                    marker.latLng = L.latLng(marker.latitude, marker.longitude);
                    if (compare) {
                        if (this.chart2Pan === 'bounds') {
                            let coords = [];
                            for (const key in this.markers) {
                                if (this.markers[key] && this.markers[key].longitude && this.markers[key].latitude) {
                                    coords.push([this.markers[key].latitude, this.markers[key].longitude]);
                                }
                            }
                            coords.push([marker.latitude, marker.longitude]);
                            this.bounds = L.latLngBounds(coords).pad(0.6);
                        } else {
                            if (this.chart2Pan === 'ship1' && shipNro === 1) {
                                this.center = L.latLng(marker.latitude, marker.longitude);
                            } else if (this.chart2Pan === 'ship2' && shipNro === 2) {
                                this.center = L.latLng(marker.latitude, marker.longitude);
                            } else {
                                this.bounds = null;
                            }
                        }
                    } else {
                        if (this.chartPan) {
                            this.center = L.latLng(marker.latitude, marker.longitude);
                        }
                    }
                    marker.rotation = shipHelpers.headingOrCog(marker.heading, marker.cog);
                    if (!this.ship || compare) {
                        this.ship = this.getShips().filter((o) => {
                            return o.id == marker.shipId;
                        })[0];
                    }
                    let prefix = this.ship.external === 1 ? 'ts' : 'os';
                    let icon = shipHelpers.shipIcon(marker.navstat, prefix, 222222222, null, marker.sog);
                    marker.icon = (
                        L.icon({
                            iconUrl: require('@/assets/img/ships/' + icon),
                            iconSize: [11, 30], // size of the icon
                            iconAnchor: [5.5, 15], // point of the icon which will correspond to marker's location
                        })
                    );
                    if (this.ship) {
                        marker.tooltip = this.ship.name;
                        marker.a = this.ship.a;
                        marker.b = this.ship.b;
                        marker.c = this.ship.c;
                        marker.d = this.ship.d;
                        marker.shipname = this.ship.shortName;
                    }
                    marker.options = {};
                    marker.options.rotationOrigin = 'center center'
                    marker.shipGeojson = shipHelpers.shipGeojson(marker);
                    marker.circles = [];
                    let radiuses = [926, 1852, 2778];
                    for (const radius of radiuses) {
                        marker.circles.push({
                            center: L.latLng(marker.latitude, marker.longitude),
                            radius: radius,
                            color: 'rgba(150, 150, 150, 0.3)',
                            weight: 0.5
                        });
                    }
                    marker.predictor = null;
                    let sog = marker.sog !== undefined ? marker.sog : marker.sog1;
                    sog = sog !== undefined ? sog : marker.sog2;
                    sog = sog !== undefined ? sog : marker.sog3;
                    let arrow = shipHelpers.vectorSvg(sog, 'white');
                    if (arrow) {
                        marker.predictor = {
                            latLng: L.latLng(marker.latitude, marker.longitude),
                            direction: shipHelpers.headingOrCog(marker.heading, marker.cog, true),
                            icon: L.icon({
                                iconUrl: arrow.image,
                                iconSize: [8, arrow.height],
                                iconAnchor: [4, arrow.height],
                            }),
                            rotationOrigin: 'center bottom',
                        };
                    }

                    marker.popup =
                        `<b>${this.ship ? this.ship.name : ''}</b><br/>
                        SOG: ${Math.round(sog*10)/10} kn<br/>
                        Draught: ${Math.round(marker.draught*10)/10} m<br/>
                        Status: <span class="${shipHelpers.getNavstat(marker.navstat).color}--text">${shipHelpers.getNavstat(marker.navstat).status}</span><br/>
                        Last seen: ${moment.utc(marker.time).format('D.M HH:mm')}<br/>`;

                    return marker;
                }
            },
            random() {
                return Math.random();
            },
            getNavstat(navstat) {
                return shipHelpers.getNavstat(navstat);
            },
            sliceData(start, end, data, type) {
                let zoomedData = [];
                let coordsLatLon = [];
                let coordsLonLat = [];
                let ship1 = [];
                let ship2 = [];
                // let ship3 = [];
                this.routeGeojsons = [];
                for (const key in data) {
                    if (data.hasOwnProperty(key) && data[key].time) {
                        if (Math.round(moment.utc(data[key].time).unix() / 600) >= Math.round(moment.utc(start).unix() / 600)) {
                            zoomedData.push(data[key]);
                            if (data[key].latitude && data[key].longitude) {
                                coordsLonLat.push([data[key].longitude, data[key].latitude]);
                            }
                            if (Math.round(moment.utc(data[key].time).unix() / 600) >= Math.round(moment.utc(end).unix() / 600)) {
                                break;
                            }
                        }
                    } else if (data[key].time1) {
                        if (Math.round(moment.utc(data[key].time1).unix() / 600) >= Math.round(moment.utc(start).unix() / 600) && Math.round(moment.utc(data[
                                key].time1).unix() / 600) <= Math.round(moment.utc(end).unix() / 600)) {
                            ship1.push([data[key].longitude, data[key].latitude]);
                        }
                    } else if (data[key].time2) {
                        if (Math.round(moment.utc(data[key].time2).unix() / 600) >= Math.round(moment.utc(start).unix() / 600) && Math.round(moment.utc(data[
                                key].time2).unix() / 600) <= Math.round(moment.utc(end).unix() / 600)) {
                            ship2.push([data[key].longitude, data[key].latitude]);
                        }
                    }
                    if (data[key].latitude && data[key].longitude) {
                        coordsLatLon.push([data[key].latitude, data[key].longitude]);
                    }
                }
                // Set bound based on sliced data
                if (coordsLatLon && coordsLatLon.length > 0) {
                    if ((this.chartPan && coordsLonLat.length > 1) || this.chart2Pan && ship1.length > 1) {
                        this.bounds = L.latLngBounds(coordsLatLon);
                    }
                }
                // Show sliced track on map
                if (coordsLonLat.length > 1) {
                    if (type === 'weather') {
                        this.routeGeojson3 = lineString(coordsLonLat, {
                            name: 'route'
                        });
                    } else if (type === 'analyze') {
                        this.routeGeojson = lineString(coordsLonLat, {
                            name: 'route'
                        });
                    }
                }
                if (type === 'compare') {
                    if (ship1.length > 1) {
                        this.routeGeojsons.push(lineString(ship1, {
                            name: 'route'
                        }));
                    }
                    if (ship2.length > 1) {
                        this.routeGeojsons.push(lineString(ship2, {
                            name: 'route'
                        }));
                    }
                }
                return zoomedData;
            },
            resetZoomedData(data, type) {
                let coordsLatLon = [];
                let coordsLonLat = [];
                let ship1 = [];
                let ship2 = [];
                // let ship3 = [];
                this.routeGeojsons = [];
                for (const key in data) {
                    if (data.hasOwnProperty(key)) {
                        if (data[key].time && data[key].latitude && data[key].longitude) {
                            coordsLonLat.push([data[key].longitude, data[key].latitude]);
                        }
                        if (data[key].latitude && data[key].longitude) {
                            coordsLatLon.push([data[key].latitude, data[key].longitude]);
                        }
                        if (data[key].time1) {
                            ship1.push([data[key].longitude, data[key].latitude]);
                        } else if (data[key].time2) {
                            ship2.push([data[key].longitude, data[key].latitude]);
                        }
                    }
                }

                // Set bound based on sliced data
                this.bounds = L.latLngBounds(coordsLatLon);
                // Show sliced track on map
                if (coordsLonLat.length > 1) {
                    if (type === 'analyze') {
                        this.routeGeojson = lineString(coordsLonLat, {
                            name: 'route'
                        });
                    } else if (type === 'weather') {
                        this.routeGeojson3 = lineString(coordsLonLat, {
                            name: 'route'
                        });
                    }
                }
                if (type === 'compare') {
                    if (ship1.length > 1) {
                        this.routeGeojsons.push(lineString(ship1, {
                            name: 'route'
                        }));
                    }
                    if (ship2.length > 1) {
                        this.routeGeojsons.push(lineString(ship2, {
                            name: 'route'
                        }));
                    }
                }
            },
            updateCompareEnabled() {
                this.compare = [];
                let ship1 = this.compareList.find((o) => this.compareId1 === o.shipId);
                if (ship1) {
                    ship1 = JSON.parse(JSON.stringify(ship1));
                    ship1.tempId = 10000001;
                    this.compare.push(ship1);
                }
                let ship2 = this.compareList.find((o) => this.compareId2 === o.shipId);
                if (ship2) {
                    ship2 = JSON.parse(JSON.stringify(ship2));
                    ship2.tempId = 10000002;
                    this.compare.push(ship2);
                }
            },
            setEndDateTime(item) {
                item.endDate = moment.utc(item.startDate).add(1, 'day').format('Y-MM-DD');
                item.endTime = item.startTime;
            },
            trendLinePoints(data) {
                let sogs = [];
                let times = [];
                for (const key in data) {
                    if (data[key].time && data[key].sog != undefined) {
                        sogs.push(data[key].sog);
                        times.push(moment.utc(data[key].time).unix());
                    }
                }
                let linear = tools.linearRegression(sogs, times); //(Y = slope * X + intercept).
                return [{
                    trendValue: Math.round((linear.slope * times[0] + linear.intercept) * 100) / 100,
                    trendTime: moment.unix(times[0]).toDate()
                }, {
                    trendValue: Math.round((linear.slope * times[times.length - 1] + linear.intercept) * 100) / 100,
                    trendTime: moment.unix(times[times.length - 1]).toDate()
                }];
            },
            setWindLimit() {
                if (this.chart3 && this.chart3.data && this.chart3.data.length > 1) {
                    // Set wind treshold lineseries data
                    this.limitSeries.name = this.windLimits.filter((o) => {
                        return o.value === this.windLimit;
                    })[0].name;
                    this.limitSeries.data = [{
                        time: this.chart3.data[0].time,
                        value: this.windLimit
                    }, {
                        time: this.chart3.data[this.chart3.data.length - 1].time,
                        value: this.windLimit
                    }];
                }
            },
            env(variableName) {
                return process.env[variableName];
            },
            checkTimespan(data, days) {
                let start = moment.utc(data.startDate + 'T' + data.startTime + ':00').unix();
                let end = moment.utc(data.endDate + 'T' + data.endTime + ':00').unix();
                if (end - start > days * 24 * 60 * 60) {
                    this.$store.dispatch('alert/error', 'Maximum time period is ' + days + ' days. Adjust your times accordingly.', {
                        root: true
                    });
                    return false;
                } else {
                    return true;
                }
            },
            clearAnalyzeChart() {
                this.analyze.startDate = moment.utc().subtract(1, 'days').format('YYYY-MM-DD');
                // this.analyze.startDate = moment.utc().format('YYYY-MM-DD');
                this.analyze.startTime = '00:00';
                this.analyze.endDate = moment.utc().format('YYYY-MM-DD');
                this.analyze.endTime = moment.utc().format('HH:mm');
                if (this.chart && this.chart.data) {
                    this.chart.data = [];
                }
            },
            clearCompareChart() {
                this.compareId1 = null;
                this.compareId2 = null;
                this.compare.length = 0;
                if (this.chart2 && this.chart2.data) {
                    this.chart2.data = [];
                }
            },
            decimalToDmm(value, lng) {
                return tools.decimalToDmm(value, lng);
            },
            clearWeatherChart() {
                this.weather.startDate = moment.utc().subtract(1, 'days').format('YYYY-MM-DD');
                // this.weather.startDate = moment.utc().format('YYYY-MM-DD');
                this.weather.startTime = '00:00';
                this.weather.endDate = moment.utc().format('YYYY-MM-DD');
                this.weather.endTime = moment.utc().format('HH:mm');
                this.windLimit = 7.9;
                if (this.chart3 && this.chart3.data) {
                    this.chart3.data = [];
                }
            },
            createCharts() {
                /********************************/
                /** Chart 1, analyse ship data **/
                /********************************/
                const container = am4core.create(this.$refs.chartdiv, am4core.Container);
                container.width = am4core.percent(100);
                container.height = am4core.percent(100);

                container.tooltip = new am4core.Tooltip();
                container.tooltip.background.fill = am4core.color("#000000");
                container.tooltip.background.stroke = am4core.color('#3498db');
                container.tooltip.fontSize = "0.9em";
                container.tooltip.getFillFromObject = false;
                container.tooltip.getStrokeFromObject = false;

                // Chart container
                const chartContainer = container.createChild(am4core.Container);
                chartContainer.width = am4core.percent(100);
                chartContainer.height = am4core.percent(92);
                chartContainer.padding(0, 15, 15, 0);
                chartContainer.valign = "bottom";
                chartContainer.layout = "vertical";

                let sliderAnimation;
                
                 // Slider container
                const sliderContainer = container.createChild(am4core.Container);
                sliderContainer.width = am4core.percent(100);
                sliderContainer.height = am4core.percent(8);
                sliderContainer.padding(0, 15, 15, 0);
                sliderContainer.valign = "top";
                sliderContainer.layout = "horizontal";

                // play button
                const playButton = sliderContainer.createChild(am4core.PlayButton);
                playButton.valign = "middle";
                playButton.marginLeft = 5;

                const slider = sliderContainer.createChild(am4core.Slider);
                slider.width = am4core.percent(100);
                slider.valign = "middle";
                slider.background.opacity = 0.4;
                slider.opacity = 0.7;
                slider.background.fill = am4core.color("#3498db");
                slider.marginLeft = 20;
                slider.marginTop = 20;
                slider.marginRight = 20;
                slider.height = 15;
                slider.start = 1;

                const chart = chartContainer.createChild(am4charts.XYChart);
                chart.dateFormatter.utc = true;
                chart.paddingRight = 20;
                chart.paddingLeft = 30;
                chart.data = [];

                // what to do when slider is dragged
                slider.events.on("rangechanged", function() {
                    if (this && this.chart) {
                        var index = Math.round((this.chart.data.length - 1) * slider.start);
                        updatePosition(index, this);
                    }
                }, this)

                // stop animation if dragged
                slider.startGrip.events.on("drag", () => {
                    stop();
                    if (sliderAnimation) {
                        sliderAnimation.setProgress(slider.start);
                    }
                });

                function updatePosition(index, vm) {
                    if (!isNaN(index) && vm) {
                        var di = vm.chart.data[index];
                        if (!di) {
                            return;
                        }
                        var date = new Date(di.time);
                        vm.currentDate = date;

                        var position = dateAxis.dateToPosition(date);
                        position = dateAxis.toGlobalPosition(position);
                        var x = dateAxis.positionToCoordinate(position);

                        if (chart.cursor) {
                            chart.cursor.triggerMove({ x: x, y: 0 }, "soft", true);
                        }
                        vm.currentIndex = index;
                    }
                }

                container.events.on("layoutvalidated", function() {
                    dateAxis.tooltip.hide();
                    chart.cursor.hide();
                    if (this.currentIndex) {
                        updatePosition(this.currentIndex, this);
                    }
                }, this);

                // play button behavior
                playButton.events.on("toggled", function(event) {
                    if (event.target.isActive) {
                        play(50000, false);
                    } else {
                        stop();
                    }
                }, this)

                // make slider grip look like play button
                slider.startGrip.background.fill = playButton.background.fill;
                slider.startGrip.background.strokeOpacity = 0;
                slider.startGrip.icon.stroke = am4core.color("#ffffff");
                slider.startGrip.background.states.copyFrom(playButton.background.states);

                // play behavior
                function play(duration, start) {
                    if (start && sliderAnimation) {
                        sliderAnimation.stop();
                        sliderAnimation = null;
                    }
                    if (!sliderAnimation) {
                        console.log('Animation duration ' + Math.round(duration/1000) + ' secs');
                        sliderAnimation = slider.animate({ property: "start", to: 1, from: 0 }, duration, am4core.ease.linear).pause();
                        sliderAnimation.events.on("animationended", () => {
                            playButton.isActive = false;
                        })
                    }

                    if (slider.start >= 1) {
                        slider.start = 0;
                        sliderAnimation.start();
                    }
                    sliderAnimation.resume();
                    playButton.isActive = true;
                }

                // stop behavior
                function stop() {
                    if (sliderAnimation) {
                        sliderAnimation.pause();
                    }
                    playButton.isActive = false;
                }

                // Playback duration custom menu
                chart.exporting.menu = new am4core.ExportMenu();
                chart.exporting.menu.align = "right";
                chart.exporting.menu.verticalAlign = "bottom";
                chart.exporting.menu.items = [{
                    label: '...',
                    menu: []
                }];
                chart.exporting.menu.items[0].menu.push({
                    label: "1x playback",
                    type: "custom",
                    options: {
                        callback: function() {
                            const data = chart.data.filter(o => o.time);
                            const duration = data[data.length-1].time.getTime() - data[0].time.getTime();
                            play(duration, true);
                        }
                    }
                });
                chart.exporting.menu.items[0].menu.push({
                    label: "10x playback",
                    type: "custom",
                    options: {
                        callback: function() {
                            const data = chart.data.filter(o => o.time);
                            const duration = Math.round((data[data.length-1].time.getTime() - data[0].time.getTime())/10);
                            play(duration, true);
                        }
                    }
                });
                chart.exporting.menu.items[0].menu.push({
                    label: "100x playback",
                    type: "custom",
                    options: {
                        callback: function() {
                            const data = chart.data.filter(o => o.time);
                            const duration = Math.round((data[data.length-1].time.getTime() - data[0].time.getTime())/100);
                            play(duration, true);
                        }
                    }
                });
                chart.exporting.menu.items[0].menu.push({
                    label: "1000x playback",
                    type: "custom",
                    options: {
                        callback: function() {
                            const data = chart.data.filter(o => o.time);
                            const duration = Math.round((data[data.length-1].time.getTime() - data[0].time.getTime())/1000);
                            play(duration, true);
                        }
                    }
                });
                chart.exporting.menu.items[0].menu.push({
                    label: "10000x playback",
                    type: "custom",
                    options: {
                        callback: function() {
                            const data = chart.data.filter(o => o.time);
                            const duration = Math.round((data[data.length-1].time.getTime() - data[0].time.getTime())/10000);
                            play(duration, true);
                        }
                    }
                });
                chart.exporting.menu.items[0].menu.push({
                    label: "Record screen",
                    type: "custom",
                    options: {
                        callback: async function() {
                            let stream = await navigator.mediaDevices.getDisplayMedia({
                                video: true,
                                preferCurrentTab: true,
                            })

                            //needed for better browser support
                            const mime = MediaRecorder.isTypeSupported("video/webm; codecs=vp9") ? "video/webm; codecs=vp9" : "video/webm";
                            let mediaRecorder = new MediaRecorder(stream, {
                                mimeType: mime
                            });

                            let chunks = []
                            mediaRecorder.addEventListener('dataavailable', function(e) {
                                chunks.push(e.data)
                            });

                            mediaRecorder.addEventListener('stop', function(){
                                let blob = new Blob(chunks, {
                                    type: chunks[0].type
                                });
                                let url = URL.createObjectURL(blob);

                                let video = document.querySelector("video");
                                video.src = url;

                                let a = document.createElement('a');
                                a.href = url;
                                a.download = 'video.webm';
                                a.click();
                            })
                            //we have to start the recorder manually
                            mediaRecorder.start();
                        }
                    }
                });


                let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
                dateAxis.tooltipDateFormat = "dd.MM HH:mm";
                dateAxis.renderer.grid.template.location = 0;

                let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
                valueAxis.tooltip.disabled = true;
                valueAxis.renderer.labels.template.fill = am4core.color("#e59165");
                valueAxis.renderer.width = 30;
                valueAxis.min = 0;
                valueAxis.extraMax = 0.05;
                valueAxis.maxZoomFactor = 10;

                let valueAxis2 = chart.yAxes.push(new am4charts.ValueAxis());
                valueAxis2.tooltip.disabled = true;
                valueAxis2.renderer.grid.template.strokeDasharray = "2,3";
                valueAxis2.renderer.labels.template.fill = am4core.color("#dfcc64");
                valueAxis2.renderer.width = 30;
                valueAxis2.renderer.opposite = true;

                let valueAxis3 = chart.yAxes.push(new am4charts.ValueAxis());
                valueAxis3.hidden = true;
                valueAxis3.tooltip.disabled = true;
                valueAxis3.min = 0;
                valueAxis3.max = 100;
                valueAxis3.renderer.labels.template.fill = am4core.color("#ff0000");
                valueAxis3.renderer.width = 30;

                let series = chart.series.push(new am4charts.LineSeries());
                series.name = "SOG";
                series.xAxis = dateAxis;
                series.dataFields.dateX = "time";
                series.dataFields.valueY = "sog";
                series.tooltipText = "{name}: {valueY.value}kn";
                series.fill = am4core.color("#e59165");
                series.stroke = am4core.color("#e59165");
                series.strokeWidth = 2;
                series.segments.template.interactionsEnabled = true;

                let series7 = chart.series.push(new am4charts.LineSeries());
                series7.name = "Events";
                series7.dataFields.dateX = "eventTime";
                series7.dataFields.valueY = "eventTypeId";
                let circleBullet = series7.bullets.push(new am4charts.CircleBullet());
                let labelBullet = series7.bullets.push(new am4charts.LabelBullet());
                circleBullet.circle.radius = 5;
                labelBullet.label.text = "{shortName}";
                labelBullet.label.dy = -15;
                labelBullet.label.fontSize = 10;
                series7.yAxis = valueAxis3;
                series7.xAxis = dateAxis;
                series7.tooltipText = "{description}";
                series7.fill = am4core.color("#ff0000");
                series7.stroke = am4core.color("#ff0000");
                series7.strokeWidth = 0;

                this.trendSeries = chart.series.push(new am4charts.LineSeries());
                this.trendSeries.name = "Trendline";
                this.trendSeries.xAxis = dateAxis;
                this.trendSeries.dataFields.dateX = "trendTime";
                this.trendSeries.dataFields.valueY = "trendValue";
                this.trendSeries.fill = am4core.color("#e59165");
                this.trendSeries.stroke = am4core.color("#e59165");
                this.trendSeries.strokeDasharray = '6 3';
                this.trendSeries.strokeWidth = 2;
                this.trendSeries.disabled = true;
                this.trendSeries.data = [{
                    trendValue: null,
                    trendTime: null
                }];

                chart.cursor = new am4charts.XYCursor();
                chart.cursor.xAxis = dateAxis;

                // let scrollbarX = new am4charts.XYChartScrollbar();
                // scrollbarX.series.push(series);
                // chart.scrollbarX = scrollbarX;
                // chart.scrollbarX.startGrip.disabled = true;
                // chart.scrollbarX.endGrip.disabled = true;

                chart.legend = new am4charts.Legend();
                chart.legend.zIndex = 100;
                chart.legend.opacity = 0.8;
                chart.legend.useDefaultMarker = true;
                let markerTemplate = chart.legend.markers.template;
                markerTemplate.width = 12;
                markerTemplate.height = 12;
                chart.legend.itemContainers.template.paddingTop = 0;
                chart.legend.itemContainers.template.paddingBottom = 0;
                chart.legend.position = 'bottom';

                valueAxis2.renderer.grid.template.strokeOpacity = 0.07;
                dateAxis.renderer.grid.template.strokeOpacity = 0.07;
                valueAxis.renderer.grid.template.strokeOpacity = 0.07;

                this.chart = chart;

                // Track ship on map based on lineSeries hover
                series.segments.template.events.on("over", ev => {
                    if (this.chartTrackType === 'lineSeries') {
                        let marker = ev.target.dataItem.component.tooltipDataItem.dataContext;
                        this.marker = this.trackShipOnMap(marker, false, null);
                    }
                }, this);

                // Track ship on map based on cursor position
                series.tooltip.events.on("positionchanged", function(ev) {
                    if (this.chartTrackType === 'cursor') {
                        let marker = ev.target.dataItem.dataContext;
                        this.marker = this.trackShipOnMap(marker, false, null);
                    }
                }, this);

                // Zoom map to extent on chart zoomed data timeframe
                chart.cursor.events.on("zoomended", function(ev) {
                    let range = ev.target.xRange;
                    let axis = ev.target.chart.xAxes.getIndex(0);
                    if (range && range.start && range.end) {
                        const start = axis.positionToDate(axis.toAxisPosition(range.start));
                        const end = axis.positionToDate(axis.toAxisPosition(range.end));
                        let zoomedData = this.sliceData(start, end, this.chart.data, 'analyze');
                        // Show trendline based on zoomed data
                        let trendLineData = this.trendLinePoints(zoomedData);
                        this.trendSeries.disabled = false;
                        this.trendSeries.data = trendLineData;
                        // Update date & time pickers on zoom
                        this.analyze.startDate = moment.utc(start).format('YYYY-MM-DD');
                        this.analyze.startTime = moment.utc(start).format('HH:mm');
                        this.analyze.endDate = moment.utc(end).format('YYYY-MM-DD');
                        this.analyze.endTime = moment.utc(end).format('HH:mm');
                    }
                }, this);

                // // Same on scrollbar (start, end values are not working so data is reseted only)
                // chart.scrollbarX.events.on("rangechanged", function () {
                //     if (this.timeoutFired) {
                //         this.timeoutFired = false;
                //         setTimeout(() => {
                //             this.resetZoomedData(this.chart.data, 'analyze');
                //             this.timeoutFired = true;
                //         }, 700);
                //     }
                // }, this);

                // Reset data on zoomout button
                chart.zoomOutButton.events.on("hit", function() {
                    this.resetZoomedData(this.chart.data, 'analyze');
                    this.trendSeries.data = [];
                    this.trendSeries.disabled = true;
                }, this);

                /****************************/
                /** Chart 2, compare ships **/
                /****************************/
                let chart2 = am4core.create(this.$refs.chartdiv2, am4charts.XYChart);
                chart2.dateFormatter.utc = true;
                chart2.paddingRight = 20;
                chart2.data = [];

                let chart2dateAxis = chart2.xAxes.push(new am4charts.DateAxis());
                chart2dateAxis.tooltipDateFormat = "dd.MM HH:mm";
                chart2dateAxis.renderer.grid.template.location = 0;

                let chart2dateAxis2 = chart2.xAxes.push(new am4charts.DateAxis());
                chart2dateAxis2.tooltipDateFormat = "dd.MM HH:mm";
                chart2dateAxis2.renderer.grid.template.location = 0;

                // let chart2dateAxis3 = chart2.xAxes.push(new am4charts.DateAxis());
                // chart2dateAxis3.tooltipDateFormat = "dd.MM HH:mm";
                // chart2dateAxis3.renderer.grid.template.location = 0;

                let chart2valueAxis = chart2.yAxes.push(new am4charts.ValueAxis());
                chart2valueAxis.tooltip.disabled = true;
                chart2valueAxis.renderer.labels.template.fill = am4core.color("#e59165");
                chart2valueAxis.renderer.width = 30;
                chart2valueAxis.min = 0;
                chart2valueAxis.extraMax = 0.05;
                chart2valueAxis.maxZoomFactor = 10;

                let chart2series = chart2.series.push(new am4charts.LineSeries());
                chart2series.name = "SOG";
                chart2series.dataFields.dateX = "time1";
                chart2series.dataFields.valueY = "sog1";
                chart2series.tooltipText = "{name}: {valueY.value}kn";
                chart2series.fill = am4core.color("#e59165");
                chart2series.stroke = am4core.color("#e59165");
                chart2series.strokeWidth = 2;
                chart2series.segments.template.interactionsEnabled = true;

                let chart2series2 = chart2.series.push(new am4charts.LineSeries());
                chart2series2.name = "SOG";
                chart2series2.dataFields.dateX = "time2";
                chart2series2.dataFields.valueY = "sog2";
                chart2series2.xAxis = chart2dateAxis2;
                chart2series2.tooltipText = "{name}: {valueY.value}kn";
                chart2series2.fill = am4core.color("#0652DD");
                chart2series2.stroke = am4core.color("#0652DD");
                chart2series2.strokeWidth = 2;
                chart2series2.segments.template.interactionsEnabled = true;

                chart2.cursor = new am4charts.XYCursor();
                chart2.cursor.xAxis = chart2dateAxis2;

                // let scrollbarX2 = new am4charts.XYChartScrollbar();
                // scrollbarX2.series.push(chart2series);
                // chart2.scrollbarX = scrollbarX2;
                // chart2.scrollbarX.startGrip.disabled = true;
                // chart2.scrollbarX.endGrip.disabled = true;

                chart2.legend = new am4charts.Legend();
                chart2.legend.zIndex = 100;
                chart2.legend.opacity = 0.8;
                chart2.legend.useDefaultMarker = true;
                let markerTemplate2 = chart2.legend.markers.template;
                markerTemplate2.width = 12;
                markerTemplate2.height = 12;
                chart2.legend.itemContainers.template.paddingTop = 0;
                chart2.legend.itemContainers.template.paddingBottom = 0;
                chart2.legend.position = 'bottom';

                chart2dateAxis.renderer.grid.template.strokeOpacity = 0.07;
                chart2dateAxis2.renderer.grid.template.strokeOpacity = 0.07;
                chart2valueAxis.renderer.grid.template.strokeOpacity = 0.07;

                this.chart2 = chart2;

                // Track ship on map based on lineSeries hover
                chart2series.segments.template.events.on("over", ev => {
                    if (this.chartTrackType2 === 'lineSeries') {
                        let marker = ev.target.dataItem.component.tooltipDataItem.dataContext;
                        this.markers[0] = this.trackShipOnMap(marker, true, 1);
                    }
                }, this);
                chart2series2.segments.template.events.on("over", ev => {
                    if (this.chartTrackType2 === 'lineSeries') {
                        let marker = ev.target.dataItem.component.tooltipDataItem.dataContext;
                        this.markers[1] = this.trackShipOnMap(marker, true, 2);
                    }
                }, this);

                // Track ship on map based on cursor position
                chart2series.tooltip.events.on("positionchanged", function(ev) {
                    if (this.chartTrackType2 === 'cursor') {
                        let marker = ev.target.dataItem.dataContext;
                        let bounds = Object.assign({}, this.bounds); // Store old bounds
                        this.markers[0] = this.trackShipOnMap(marker, true, 1);
                        this.bounds = bounds;
                    }
                }, this);
                chart2series2.tooltip.events.on("positionchanged", function(ev) {
                    if (this.chartTrackType2 === 'cursor') {
                        let marker = ev.target.dataItem.dataContext;
                        this.markers[1] = this.trackShipOnMap(marker, true, 2);
                    }
                }, this);

                // Zoom map to extent on chart zoomed data timeframe
                chart2.cursor.events.on("zoomended", function(ev) {
                    let range = ev.target.xRange;
                    let axis = ev.target.chart.xAxes.getIndex(0);
                    if (range && range.start && range.end) {
                        const start = axis.positionToDate(axis.toAxisPosition(range.start));
                        const end = axis.positionToDate(axis.toAxisPosition(range.end));
                        this.sliceData(start, end, this.chart2.data, 'compare');
                    }
                }, this);

                // // Same on scrollbar (start, end values are not working so data is reseted only)
                // chart2.scrollbarX.events.on("rangechanged", function () {
                //     if (this.timeoutFired) {
                //         this.timeoutFired = false;
                //         setTimeout(() => {
                //             this.resetZoomedData(this.chart2.data, 'compare');
                //             this.timeoutFired = true;
                //         }, 700);
                //     }
                // }, this);

                // Reset data on zoomout button
                chart2.zoomOutButton.events.on("hit", function() {
                    this.resetZoomedData(this.chart2.data, 'compare');
                }, this);


                /****************************/
                /** Chart 3, Weather track **/
                /****************************/
                let chart3 = am4core.create(this.$refs.chartdiv3, am4charts.XYChart);
                chart3.dateFormatter.utc = true;
                chart3.paddingRight = 20;
                chart3.data = [];

                let chart3dateAxis = chart3.xAxes.push(new am4charts.DateAxis());
                chart3dateAxis.tooltipDateFormat = "dd.MM HH:mm";
                chart3dateAxis.renderer.grid.template.location = 0;

                let chart3valueAxis = chart3.yAxes.push(new am4charts.ValueAxis());
                chart3valueAxis.tooltip.disabled = true;
                chart3valueAxis.renderer.labels.template.fill = am4core.color("#e59165");
                chart3valueAxis.renderer.width = 30;
                chart3valueAxis.min = 0;
                chart3valueAxis.extraMax = 0.05;
                chart3valueAxis.maxZoomFactor = 10;

                let chart3valueAxis2 = chart3.yAxes.push(new am4charts.ValueAxis());
                chart3valueAxis2.tooltip.disabled = true;
                chart3valueAxis2.renderer.grid.template.strokeDasharray = "2,3";
                chart3valueAxis2.renderer.labels.template.fill = am4core.color("#dfcc64");
                chart3valueAxis2.renderer.width = 30;
                chart3valueAxis2.renderer.opposite = true;

                let chart3series = chart3.series.push(new am4charts.LineSeries());
                chart3series.name = "SOG";
                chart3series.dataFields.dateX = "time";
                chart3series.dataFields.valueY = "sog";
                chart3series.tooltipText = "{name}: {valueY.value}kn";
                chart3series.fill = am4core.color("#e59165");
                chart3series.stroke = am4core.color("#e59165");
                chart3series.strokeWidth = 2;
                chart3series.segments.template.interactionsEnabled = true;

                // if (process.env.VUE_APP_NAME === 'danelec' || process.env.VUE_APP_NAME === 'nextfleet') {
                //     let chart3series4 = chart3.series.push(new am4charts.LineSeries());
                //     chart3series4.name = "Relative wind";
                //     chart3series4.dataFields.dateX = "time";
                //     chart3series4.dataFields.valueY = "windSpeedR";
                //     chart3series4.yAxis = chart3valueAxis;
                //     chart3series4.xAxis = chart3dateAxis;
                //     chart3series4.tooltipText = "{name}: {valueY.value}m/s {windKnotsR}kn {windDirR}°";
                //     chart3series4.fill = am4core.color("#d576a3");
                //     chart3series4.stroke = am4core.color("#d576a3");
                //     chart3series4.strokeWidth = 1;
                //     // Add a bullet
                //     let windBullet = chart3series4.bullets.push(new am4charts.Bullet());
                //     windBullet.propertyFields.rotation = "windDirR";
                //     let windArrow = windBullet.createChild(am4core.Triangle);
                //     windArrow.horizontalCenter = "middle";
                //     windArrow.verticalCenter = "middle";
                //     windArrow.stroke = am4core.color("#fff");
                //     windArrow.direction = "bottom";
                //     windArrow.width = 8;
                //     windArrow.height = 15;

                //     let chart3series5 = chart3.series.push(new am4charts.LineSeries());
                //     chart3series5.name = "True wind";
                //     chart3series5.dataFields.dateX = "time";
                //     chart3series5.dataFields.valueY = "tws";
                //     chart3series5.yAxis = chart3valueAxis;
                //     chart3series5.xAxis = chart3dateAxis;
                //     chart3series5.tooltipText = "{name}: {valueY.value}m/s {windKnots}kn {twd}°";
                //     chart3series5.fill = am4core.color("#911eb4");
                //     chart3series5.stroke = am4core.color("#911eb4");
                //     chart3series5.strokeWidth = 1;
                //     // Add a bullet
                //     let windBullet2 = chart3series5.bullets.push(new am4charts.Bullet());
                //     windBullet2.propertyFields.rotation = "twd";
                //     let windArrow2 = windBullet2.createChild(am4core.Triangle);
                //     windArrow2.horizontalCenter = "middle";
                //     windArrow2.verticalCenter = "middle";
                //     windArrow2.stroke = am4core.color("#fff");
                //     windArrow2.direction = "bottom";
                //     windArrow2.width = 8;
                //     windArrow2.height = 15;
                // } else {
                let chart3series4 = chart3.series.push(new am4charts.LineSeries());
                chart3series4.name = "Wind";
                chart3series4.dataFields.dateX = "time";
                chart3series4.dataFields.valueY = "tws";
                chart3series4.yAxis = chart3valueAxis;
                chart3series4.xAxis = chart3dateAxis;
                chart3series4.tooltipText = "{name}: {valueY.value}m/s {windKnots}kn {twd}°";
                chart3series4.fill = am4core.color("#d576a3");
                chart3series4.stroke = am4core.color("#d576a3");
                chart3series4.strokeWidth = 1;
                // Add a bullet
                let windBullet = chart3series4.bullets.push(new am4charts.Bullet());
                windBullet.propertyFields.rotation = "twd";
                let windArrow = windBullet.createChild(am4core.Triangle);
                windArrow.horizontalCenter = "middle";
                windArrow.verticalCenter = "middle";
                windArrow.stroke = am4core.color("#fff");
                windArrow.direction = "bottom";
                windArrow.width = 8;
                windArrow.height = 15;

                let chart3series5 = chart3.series.push(new am4charts.LineSeries());
                chart3series5.name = "Gusts";
                chart3series5.dataFields.dateX = "time";
                chart3series5.dataFields.valueY = "gust";
                chart3series5.yAxis = chart3valueAxis;
                chart3series5.xAxis = chart3dateAxis;
                chart3series5.tooltipText = "{name}: {valueY.value}m/s";
                chart3series5.fill = am4core.color("#b1357f");
                chart3series5.stroke = am4core.color("#b1357f");
                chart3series5.strokeWidth = 1;

                let chart3series6 = chart3.series.push(new am4charts.LineSeries());
                chart3series6.name = "Significant wave";
                chart3series6.dataFields.dateX = "time";
                chart3series6.dataFields.valueY = "swh";
                chart3series6.yAxis = chart3valueAxis2;
                chart3series6.xAxis = chart3dateAxis;
                chart3series6.tooltipText = "{name}: {valueY.value}m {pwd}°";
                chart3series6.fill = am4core.color("#0652DD");
                chart3series6.stroke = am4core.color("#0652DD");
                chart3series6.strokeWidth = 1;
                // Add a bullet
                let waveBullet = chart3series6.bullets.push(new am4charts.Bullet());
                waveBullet.propertyFields.rotation = "pwd";
                let waveArrow = waveBullet.createChild(am4core.Triangle);
                waveArrow.horizontalCenter = "middle";
                waveArrow.verticalCenter = "middle";
                waveArrow.stroke = am4core.color("#fff");
                waveArrow.direction = "bottom";
                waveArrow.width = 8;
                waveArrow.height = 15;

                let chart3series7 = chart3.series.push(new am4charts.LineSeries());
                chart3series7.name = "Combined current";
                chart3series7.dataFields.dateX = "time";
                chart3series7.dataFields.valueY = "cspd";
                chart3series7.yAxis = chart3valueAxis2;
                chart3series7.xAxis = chart3dateAxis;
                chart3series7.tooltipText = "{name}: {valueY.value}m/s {cdir}°";
                chart3series7.fill = am4core.color("#2ecc71");
                chart3series7.stroke = am4core.color("#2ecc71");
                chart3series7.strokeWidth = 1;
                // Add a bullet
                let currentBullet = chart3series7.bullets.push(new am4charts.Bullet());
                currentBullet.propertyFields.rotation = "cdir";
                let currentArrow = currentBullet.createChild(am4core.Triangle);
                currentArrow.horizontalCenter = "middle";
                currentArrow.verticalCenter = "middle";
                currentArrow.stroke = am4core.color("#fff");
                currentArrow.direction = "top";
                currentArrow.width = 8;
                currentArrow.height = 15;
                

                // Wind limitSeries line
                this.limitSeries = chart3.series.push(new am4charts.LineSeries());
                this.limitSeries.dataFields.dateX = "time";
                this.limitSeries.dataFields.valueY = "value";
                this.limitSeries.fill = am4core.color("#d576a3");
                this.limitSeries.stroke = am4core.color("#d576a3");
                this.limitSeries.strokeDasharray = '6 3';
                this.limitSeries.strokeWidth = 2;

                chart3.cursor = new am4charts.XYCursor();
                chart3.cursor.xAxis = chart3dateAxis;

                // let scrollbarX3 = new am4charts.XYChartScrollbar();
                // scrollbarX3.series.push(chart3series);
                // chart3.scrollbarX = scrollbarX3;
                // chart3.scrollbarX.startGrip.disabled = true;
                // chart3.scrollbarX.endGrip.disabled = true;

                chart3.legend = new am4charts.Legend();
                chart3.legend.zIndex = 100;
                chart3.legend.opacity = 0.8;
                chart3.legend.useDefaultMarker = true;
                let markerTemplate3 = chart3.legend.markers.template;
                markerTemplate3.width = 12;
                markerTemplate3.height = 12;
                chart3.legend.itemContainers.template.paddingTop = 0;
                chart3.legend.itemContainers.template.paddingBottom = 0;
                chart3.legend.position = 'bottom';

                chart3valueAxis2.renderer.grid.template.strokeOpacity = 0.07;
                chart3dateAxis.renderer.grid.template.strokeOpacity = 0.07;
                chart3valueAxis.renderer.grid.template.strokeOpacity = 0.07;

                this.chart3 = chart3;

                // Track ship on map based on lineSeries hover
                chart3series.segments.template.events.on("over", ev => {
                    if (this.chartTrackType === 'lineSeries') {
                        let marker = ev.target.dataItem.component.tooltipDataItem.dataContext;
                        this.marker3 = this.trackShipOnMap(marker, false, null);
                    }
                }, this);

                // Track ship on map based on cursor position
                chart3series.tooltip.events.on("positionchanged", function(ev) {
                    if (this.chartTrackType === 'cursor') {
                        let marker = ev.target.dataItem.dataContext;
                        this.marker3 = this.trackShipOnMap(marker, false, null);
                    }
                }, this);

                // Zoom map to extent on chart zoomed data timeframe
                chart3.cursor.events.on("zoomended", function(ev) {
                    let range = ev.target.xRange;
                    let axis = ev.target.chart.xAxes.getIndex(0);
                    if (range && range.start && range.end) {
                        const start = axis.positionToDate(axis.toAxisPosition(range.start));
                        const end = axis.positionToDate(axis.toAxisPosition(range.end));
                        this.sliceData(start, end, this.chart3.data, 'weather');
                        // Update date & time pickers on zoom
                        this.weather.startDate = moment.utc(start).format('YYYY-MM-DD');
                        this.weather.startTime = moment.utc(start).format('HH:mm');
                        this.weather.endDate = moment.utc(end).format('YYYY-MM-DD');
                        this.weather.endTime = moment.utc(end).format('HH:mm');
                    }
                }, this);

                // // Same on scrollbar (start, end values are not working so data is reseted only)
                // chart3.scrollbarX.events.on("rangechanged", function () {
                //     if (this.timeoutFired) {
                //         this.timeoutFired = false;
                //         setTimeout(() => {
                //             this.resetZoomedData(this.chart3.data, 'weather');
                //             this.timeoutFired = true;
                //         }, 700);
                //     }
                // }, this);

                // Reset data on zoomout button
                chart3.zoomOutButton.events.on("hit", function() {
                    this.resetZoomedData(this.chart3.data, 'weather');
                }, this);

                // Listen legend to hide/show arrow bullets
                // chart3.legend.itemContainers.template.events.on("hit", function(ev) {
                //     setTimeout(() => {
                //         this.chart3.series.getIndex(3).bullets.getIndex(0).children.getIndex(0).show();
                //         this.chart3.series.getIndex(3).bullets.getIndex(0).children.getIndex(0).visible = this.chart3.series.getIndex(3).visible;
                //         this.chart3.series.getIndex(5).bullets.getIndex(0).children.getIndex(0).visible = this.chart3.series.getIndex(5).visible;
                //         this.chart3.series.getIndex(6).bullets.getIndex(0).children.getIndex(0).visible = this.chart3.series.getIndex(6).visible;
                //     }, 1000);
                // }, this);
            }
        }
    }
</script>

<style>
    .v-window__container--is-active {
        overflow: visible;
    }
</style>
<style scoped>
    .chart,
    .map {
        width: 100%;
        height: 500px;
    }

    .control-btn {
        position: absolute;
    }
</style>